import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import Checked from '@/assets/svg/checked.svg';

interface Props extends HTMLAttributes<HTMLInputElement> {
  checked: boolean;
  onChange: () => void;
  label?: string;
  id?: string;
}

function Checkbox({ checked, label, id, onChange }: Props) {
  return (
    <Component>
      <Input onChange={onChange} checked={checked} id={id} type="checkbox" />
      <Label htmlFor={id} dangerouslySetInnerHTML={{ __html: label ?? '' }} />
    </Component>
  );
}

export default Checkbox;

const Component = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  cursor: pointer;
  font-size: 18px;
  line-height: 38px;
  color: ${colors.black};
  user-select: none;

  a {
    color: ${colors.orange};
    text-decoration: underline;
    text-decoration-color: ${colors.orange};
    transition: all 0.3s linear;

    &:hover {
      text-decoration-color: transparent;
    }
  }

  ${media.laptop(css`
    font-size: 16px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    font-size: 16px;
    line-height: 28px;
  `)}

  ${media.mobile(css`
    font-size: 12px;
  `)}
`;

const Input = styled.input`
  border: 1px solid ${colors.black};
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 12px;

  &:checked {
    border: none;
    background: ${`url(${Checked})`};
  }
`;
