import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import ReactSelect, { components } from 'react-select';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

const customStyles = {
  control: (provided: any, state: any) => {
    return {
      ...provided,
      borderColor: state.menuIsOpen
        ? '#999999 !important'
        : '#020202 !important',
    };
  },

  option: (provided: any, state: any) => {
    return {
      ...provided,
      color: state.isSelected || state.isFocused ? '#F9683A' : '#020202',
    };
  },

  valueContainer: (provided: any, state: any) => ({
    ...provided,
  }),

  menu: (provided: any, state: any) => ({
    ...provided,
  }),

  singleValue: (provided: any, state: any) => ({
    ...provided,
  }),

  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
  }),

  indicatorSeparator: (provided: any, state: any) => ({
    display: 'none',
  }),
};

const customStylesFromVacancy = {
  control: (provided: any, state: any) => {
    return {
      ...provided,
      borderColor: state.menuIsOpen
        ? '#999999 !important'
        : '#020202 !important',
    };
  },

  option: (provided: any, state: any) => {
    return {
      ...provided,
      padding: '17px 17px',
      fontFamily: 'Raleway',
      fontSize: '32px',
      lineHeight: '42px',
      cursor: 'pointer',
      background: 'transparent',
      color: state.isSelected || state.isFocused ? '#F9683A' : '#020202',
      fontWeight: state.isSelected ? 600 : 500,
      '@media (max-width: 1600px)': {
        ...provided['@media (max-width: 1600px)'],
        fontSize: '20px',
        lineHeight: '26px',
      },
    };
  },

  valueContainer: (provided: any, state: any) => ({
    ...provided,
    padding: '0',
    fontFamily: 'Raleway',
    fontWeight: '500',
    fontSize: '32px',
    lineHeight: '42px',
    '@media (max-width: 1600px)': {
      ...provided['@media (max-width: 1600px)'],
      fontSize: '20px',
      lineHeight: '26px',
    },
  }),

  menu: (provided: any, state: any) => ({
    ...provided,
    boxShadow: '0 0 5px #bbb',
    marginTop: '0',
    overflowY: 'auto',
    scrollbarWidth: 'thin',
  }),

  singleValue: (provided: any, state: any) => ({
    ...provided,
    fontSize: '32px',
    lineHeight: '42px',
    color: '#020202',
    '@media (max-width: 1600px)': {
      ...provided['@media (max-width: 1600px)'],
      fontSize: '20px',
      lineHeight: '26px',
    },
  }),

  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    color: '#020202 !important',
    padding: '2px',
    svg: {
      width: '38px',
      height: '38px',
    },
    '@media (max-width: 1600px)': {
      ...provided['@media (max-width: 1600px)'],
      svg: {
        width: '30px',
        height: '30px',
      },
    },
  }),

  indicatorSeparator: (provided: any, state: any) => ({
    display: 'none',
  }),
};

type Props = {
  placeholder: string;
  optionList?: any;
  onChange?: (value: any, valueId?: number) => void;
  activeValue?: string | number;
  isVacancies?: boolean;
  isLabel?: boolean;
  isVacancyForm?: boolean;
  isDisabled?: boolean;
  isClearValue?: boolean;
  defaultValue?: string | number;
  isFeedback?: boolean;
  isSubscribe?: boolean;
  isWhite?: boolean;
};

function SelectComponent({
  placeholder,
  optionList,
  onChange,
  activeValue,
  isVacancies,
  isLabel,
  isVacancyForm,
  isDisabled,
  isClearValue,
  defaultValue,
  isFeedback,
  isSubscribe,
  isWhite,
}: Props) {
  const [isActiveValue, setActiveValue] = useState<string | number | null>(
    defaultValue ? defaultValue : null
  );

  const updateForm = (value: any) => {
    setActiveValue(value);
  };

  const resetForm = () => {
    setActiveValue(null);
  };

  useEffect(() => {
    setActiveValue(defaultValue ?? null);
  }, [defaultValue]);

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <svg
          width="28"
          height="17"
          viewBox="0 0 28 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M26.5 1.75L14 14.25L1.5 1.75"
            fill="transparent"
            stroke="currentColor"
            strokeWidth="3.75"
            strokeLinejoin="round"
          />
        </svg>
      </components.DropdownIndicator>
    );
  };

  const Option = (props: any) => {
    return (
      <components.Option {...props}>
        {props.isSelected ? (
          <svg
            width="38"
            height="38"
            viewBox="0 0 38 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="38" height="38" fill="white" />
            <path
              d="M9.5 18.9993L15.8333 25.3327L28.5 12.666"
              stroke="#F9683A"
              strokeWidth="3.16667"
            />
          </svg>
        ) : null}
        {props.label}
      </components.Option>
    );
  };

  const options: Array<{ value: number; label: string }> = [];
  optionList?.forEach((optionItem: any, index: number) => {
    if (isVacancies) {
      optionItem.vacancies.forEach((vacancyItem: any, index: number) => {
        options.push({
          value: vacancyItem.id,
          label: vacancyItem.name,
        });
      });
    } else {
      options.push({
        value: optionItem.id,
        label: optionItem.name,
      });
    }
  });

  useEffect(() => {
    if (!isClearValue) return;
    resetForm();
  }, [isClearValue]);

  return (
    <Component>
      <Inner
        isFeedback={isFeedback}
        isSubscribe={isSubscribe}
        isWhite={isWhite}
      >
        <ReactSelect
          styles={isVacancyForm ? customStylesFromVacancy : customStyles}
          classNamePrefix="react-select"
          placeholder={<span>{placeholder}</span>}
          options={options}
          value={
            isLabel
              ? options.filter(({ label }) => label === isActiveValue)
              : options.filter(({ value }) => value === isActiveValue)
          }
          noOptionsMessage={() => 'нет вариантов'}
          isDisabled={isDisabled}
          onChange={(value) => {
            if (onChange && isLabel) {
              onChange(value?.label ?? '', value?.value);
              updateForm(value?.label);
            } else if (onChange) {
              onChange(value?.value ?? null);
              updateForm(value?.value);
            }
          }}
          components={{ DropdownIndicator, Option }}
        />
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  width: 100%;
`;
const Inner = styled.div<{
  isFeedback?: boolean;
  isSubscribe?: boolean;
  isWhite?: boolean;
}>`
  .react-select {
    &__control {
      border: none;
      border-bottom: 2px solid #020202;
      border-radius: 0;
      min-height: 52px;
      cursor: pointer;
      background: transparent;
      transition: all 0.3s linear;

      box-shadow: 0 0 !important;
      ${media.laptop(css`
        border-bottom: 1px solid #020202;
        min-height: 30px;
      `)}
    }

    &__option {
      padding: 7px 10px 7px 46px;
      font-family: Raleway;
      font-weight: 500;
      font-size: 26px;
      line-height: 38px;
      cursor: pointer;
      background: transparent;

      ${media.laptop(css`
        font-size: 24px;
        line-height: 32px;
        padding: 9px 10px 9px 30px;
      `)}
      ${media.tablet(css`
        font-size: 20px;
        line-height: 100%;
        padding: 4px 10px 4px 30px;
      `)}
      ${media.mobile(css`
        font-size: 16px;
      `)}
      &--is-selected {
        position: relative;

        svg {
          position: absolute;
          content: '';
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 38px;
          height: 38px;

          ${media.laptop(css`
            width: 30px;
            height: 30px;
          `)}
        }
      }
    }

    &__value-container {
      padding: 0;
      font-family: Raleway;
      font-weight: 500;
      font-size: 32px;
      line-height: 42px;
      transition: all 0.3s linear;

      ${media.laptop(css`
        font-size: 24px;
        line-height: 32px;
      `)}

      ${media.tablet(css`
        font-size: 20px;
        line-height: 100%;
      `)}

      ${media.mobile(css`
        font-size: 16px;
      `)}
    }

    &__placeholder {
      opacity: 0.4;
      color: ${colors.black};
      transition: all 0.3s linear;
    }

    &__menu {
      padding: 26px 20px;
      margin-top: -2px;
      border-radius: 0;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 18px 88px rgba(0, 0, 0, 0.14);
      z-index: 6;

      ${media.laptop(css`
        padding: 20px 10px;
        margin-top: -1px;
      `)}
    }

    &__menu-list {
      overflow-y: auto;
      scrollbar-color: ${colors.orange} transparent;
      scrollbar-width: thin;
      z-index: 5;
      margin: -7px 0;

      ${media.laptop(css`
        margin: -9px 0;
      `)}
      &::-webkit-scrollbar {
        width: 0.375rem;

        &-track {
          background-color: transparent;
        }

        &-thumb {
          background-color: ${colors.orange};

          &:hover {
            background-color: ${colors.orangeHover};
          }
        }
      }
    }

    &__single-value {
      font-size: 32px;
      line-height: 42px;
      color: #020202;
      transition: all 0.3s linear;

      ${media.laptop(css`
        font-size: 24px;
        line-height: 32px;
      `)}

      ${media.tablet(css`
        font-size: 18px;
        line-height: 22px;
      `)}

      ${media.mobile(css`
        font-size: 14px;
        line-height: 20px;
      `)}
    }

    &__dropdown-indicator {
      color: #020202 !important;
      padding: 2px 9px;
      transition: all 0.3s linear;

      svg {
        width: 22px;
        height: 22px;
      }

      ${media.mobile(css`
        svg {
          width: 15px;
          height: 9px;
        }
      `)}
    }

    //modified
    &--is-disabled {
      .react-select {
        &__control {
          border-color: rgba(203, 204, 218, 0.6) !important;
        }

        &__value-container,
        &__placeholder {
          color: rgba(127, 131, 164, 0.5);
        }

        &__dropdown-indicator {
          color: rgba(127, 131, 164, 0.5) !important;
        }
      }
    }

    &__control--menu-is-open {
      .react-select {
        &__dropdown-indicator {
          transform: rotate(180deg);
        }
      }
    }
  }

  ${(props) =>
    props.isFeedback
      ? css`
          .react-select {
            &__value-container {
              font-size: 30px;
              line-height: 36px;

              ${media.laptop(css`
                font-size: 20px;
                line-height: 26px;
              `)}

              ${media.tablet(css`
                font-size: 18px;
                line-height: 22px;
              `)}

            ${media.mobile(css`
                font-size: 14px;
                line-height: 20px;
              `)}
            }

            &__dropdown-indicator {
              margin-top: 10px;
              padding: 2px 9px 2px;

              ${media.tablet(css`
                margin-top: 0;
              `)}
              svg {
                width: 16px;
                height: 16px;
              }
            }

            &__input {
              input {
                height: 48px;

                ${media.laptop(css`
                  height: 20px;
                `)}
              }
            }

            &__single-value {
              font-size: 30px;
              line-height: 36px;

              ${media.laptop(css`
                font-size: 20px;
                line-height: 26px;
              `)}

              ${media.tablet(css`
                font-size: 18px;
                line-height: 22px;
              `)}

            ${media.mobile(css`
                font-size: 14px;
                line-height: 20px;
              `)}
            }
          }
        `
      : ''}

  ${(props) =>
    props.isSubscribe
      ? css`
          .react-select {
            &__control {
              border: none;
              border-bottom: 4px solid ${colors.black};
              border-radius: 0;
              padding: 11px 0;
              cursor: pointer;
              background: transparent;
              transition: all 0.3s linear;

              box-shadow: 0 0 !important;
              ${media.laptop(css`
                border-bottom: 2px solid ${colors.black};
                padding: 2px 0;
              `)}
            }

            &__input {
              input {
                ${media.laptop(css`
                  height: 30px;
                `)}

                ${media.tablet(css`
                  padding: 0;
                  margin: 0;
                  height: 20px;
                `)}
              }

              ${media.tablet(css`
                padding: 0;
                margin: 0;
              `)}
            }

            &__menu {
              max-height: 260px;
              margin-top: -4px;

              ${media.laptop(css`
                max-height: 130px;
                margin-top: -2px;
              `)}
              &-list {
                max-height: 220px;
                ${media.laptop(css`
                  max-height: 110px;
                `)}
              }
            }
          }
        `
      : ''}

  ${(props) =>
    props.isWhite
      ? css`
          .react-select {
            &__control {
              border-color: ${colors.white} !important;

              ${media.laptop(css`
                border-bottom: 2px solid ${colors.white};
                padding: 4px 0;
              `)}
            }

            &__single-value,
            &__value-container {
              color: ${colors.white} !important;
            }

            &__placeholder {
              opacity: 1;
              color: ${colors.grayLight} !important;
              transition: all 0.3s linear;
            }

            &__dropdown-indicator {
              color: ${colors.white} !important;
            }
          }
        `
      : ''}
`;

export default SelectComponent;
