import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';

export const Container = styled.div`
  position: relative;
`;

function getBorderColor(
  props: { invalid?: boolean; active?: boolean } = {}
): string {
  return props.invalid
    ? colors.red
    : props.active
    ? 'rgba(2,2,2,0.4)'
    : '#020202';
}

export const Input = styled.input<{
  withPrefix?: boolean;
  invalid?: boolean;
}>`
  display: block;
  width: 100%;
  height: 76px;
  padding: 18px 0;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: ${colors.black};
  background-color: transparent;
  background-clip: padding-box;
  border-bottom: 4px solid
    ${(props) => getBorderColor({ invalid: props.invalid })};
  transition: border-color 0.15s ease-in-out;

  ${(props) =>
    props.withPrefix
      ? css`
          padding-left: 45px;
        `
      : ''};

  // Placeholder
  &::placeholder {
    color: ${(props) => (props.invalid ? colors.red : 'rgba(2,2,2,0.4)')};
    opacity: 1;
  }

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &:disabled,
  &[readonly] {
    background-color: transparent;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
  }

  &:not([readonly]):focus {
    color: #020202;
    border-color: ${(props) =>
      getBorderColor({ invalid: props.invalid, active: !props.readOnly })};
    outline: 0;
  }
`;

export const prefixCss = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  pointer-events: none;
`;

export const Prefix = styled.svg`
  ${prefixCss};
`;
